.trip-card-section {
  display: flex;
  padding: 60px;
  gap: 10px;
  justify-content: space-around;
  background-image: url("../../../assets/yoo.jpg");
  background-size: cover;
}
@media screen and (max-width: 720px) {
  .trip-card-section {
    flex-direction: column;
    align-items: center;
  }
}
