.third-session {
  /* height: 500px; */
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("../../../assets/of.jpg");
  padding: 20px 80px;
}
.third-session .content {
  flex: 1;
}
.third-session .content .title {
  font-size: 50px;
  font-family: "monaco";
  color: black;

  animation: fadeIn 3s forwards;
}
.third-session .features {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.third-session .features .rows {
  display: flex;
  margin: 10px;
}
.third-session .features .rows .box {
  font-size: 26px;
  color: black;
  padding: 10px;
  width: 150px;
  height: 100px;
  margin-top: 80px;
  margin: 20px;
  border-radius: 10px;
  background: linear-gradient(
    89.1deg,
    rgba(249, 183, 31, 0.588),
    rgb(255, 191, 0)
  );
  box-shadow: 5px 5px 10px -2px var(--black);
}

@media screen and (max-width: 720px) {
  .third-session {
    padding: 20px 40px;
    flex-direction: column;
  }
}

@media screen and (max-width: 550px) {
  .third-session .content .title {
    font-size: 40px;
  }
  .third-session .features .rows {
    flex-direction: column;
  }
}

@media screen and (max-width: 450px) {
  .third-session .content .title {
    font-size: 30px;
  }
}
