.search-section {
  display: flex;
  justify-content: center;
  position: relative;
}
.search-section .background-image {
  width: 100%;
  height: 400px;
  object-fit: cover;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  /* filter: blur(8px);
  -webkit-filter: blur(8px); */
}

.search-section .search-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  position: absolute;
  padding: 40px;
  margin-top: 80px;
  z-index: 1;
}
/* header */
.search-section .search-container .search-header {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 14px;
  width: 170px;
  border-radius: 20px;
  padding: 3px;
  background: linear-gradient(89.1deg, var(--white), var(--white));
  box-shadow: 5px 5px 10px -2px var(--black);
}
.search-section .search-container .search-header > * {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  padding: 10px;
  border-radius: 20px;
}
.search-section .search-container .search-header > * span {
}
.search-section .search-container .search-header > *.selected {
  background-color: var(--lightYellow);
}
/* search area */
.search-section .search-container .search-area {
  padding: 30px;
}
.search-section .search-container .search-area-parcel {
  display: flex;
  border-radius: 10px;
  padding: 10px;
  background: linear-gradient(89.1deg, var(--white), var(--white));
  box-shadow: 5px 5px 10px -2px var(--black);
  padding: 30px;
}
/* button */
.search-section .search-container .search-button {
  outline: none;
  border: none;
  cursor: pointer;
  position: absolute;
  bottom: 24px;
  height: 30px;
  padding: 0 20px;

  background: linear-gradient(89.1deg, var(--lightYellow), var(--lightYellow));
  box-shadow: 5px 5px 10px -2px var(--black);
  border-radius: 4px;

  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: var(--black);
}
.search-section .search-container .search-button:hover {
  background: var(--white);
}
/* date-picker */

.search-section .search-container .date .react-datepicker__day--selected {
  background-color: var(--yellow);
}

.search-section .search-container .date .react-datepicker__month-container {
  float: left;
  background: linear-gradient(89.1deg, var(--white), var(--white));
  box-shadow: 5px 5px 10px -2px var(--black);
}

/* hover text */
.search-section .search-container .hover-text {
  position: absolute;
  bottom: -50px;
  font-weight: 600;
  font-size: 40px;
  color: var(--white);
}
@media screen and (max-width: 1000px) {
  .search-section .search-container .hover-text {
    bottom: -40px;
    font-size: 30px;
  }
}
@media screen and (max-width: 550px) {
  .search-section .search-container {
    margin-top: 10px !important;
  }
  .search-section .search-container .hover-text {
    display: none;
  }
}
@media screen and (max-width: 450px) {
  .search-section .search-container {
    padding: 35px;
  }
}
