.header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 50;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 20px;
  background-color: var(--white);
  color: var(--white);

  -webkit-box-shadow: 0px 2px 14px 1px var(--black);
  box-shadow: 0px 2px 14px 1px var(--black);
}
.header .titles {
  display: flex;
  justify-content: space-between;
  width: 75%;
  color: black;
}
.header .titles .logo {
  cursor: pointer;
  width: 161px;
  height: 68px;
}
.header .titles .title {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.header .titles .title > span {
  padding-right: 5px;
}
.header .auth-button {
  width: 130px;
  height: 40px;
  color: var(--white);
  border-radius: 40px;
  padding: 10px 25px;
  background: transparent;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, 0.5),
    7px 7px 20px 0px rgba(0, 0, 0, 0.1), 4px 4px 5px 0px rgba(0, 0, 0, 0.1);
  outline: none;

  background: var(--black);
  border: none;
  z-index: 1;
}
.header .auth-button:after {
  position: absolute;
  content: "";
  width: 0;
  height: 100%;
  top: 0;
  right: 0;
  z-index: -1;
  background-color: var(--yellow);
  border-radius: 40px;
  box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, 0.5),
    7px 7px 20px 0px rgba(0, 0, 0, 0.1), 4px 4px 5px 0px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}
.header .auth-button:hover {
  color: var(--black);
}
.header .auth-button:hover:after {
  left: 0;
  width: 100%;
}
.header .auth-button:active {
  top: 2px;
}
.header .auth-button.logout:hover span {
  display: none;
}
.header .auth-button.logout:hover:before {
  content: "Logout";
}
