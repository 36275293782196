.admin.bookings .filter {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 10px;
}
.admin.bookings .example-custom-input {
  min-width: 100px;
  min-height: 100%;
}
.admin.bookings button {
  cursor: pointer;

  outline: none;
  border: none;

  font-weight: 600;
  color: var(--white);
  border-radius: 6px;
  padding: 6px;
  background: linear-gradient(49.73deg, var(--yellow), var(--yellow));
}
.admin.bookings button.clear {
  background: linear-gradient(49.73deg, var(--red), var(--red));
}
.admin.bookings .react-datepicker__input-container {
  height: 100%;
}
.admin.bookings .react-datepicker-wrapper .react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background-color: var(--yellow) !important;
}

/* bookings */

.admin.bookings .list-content {
  flex-direction: column;
  font-weight: 500;
  color: var(--gray);
}
.admin.bookings .list-content .main-container {
  display: flex;
  justify-content: space-between;
}
.admin.bookings .list-content .city-name {
  color: var(--blue);
}
.admin.bookings .list-content .price > span {
  color: var(--red);
}
.admin.bookings .list-content .driver > span {
  color: var(--blue);
}

@media screen and (max-width: 720px) {
  .admin.bookings .list-content > div {
    flex-direction: column;
    padding: 5px 0;
  }
}
