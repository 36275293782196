.sidenav .handburg-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 35px;
  height: 31px;
  left: 20px;
  top: 20px;
  z-index: 100;
  cursor: pointer;
  background-color: var(--white);
  border-radius: 50%;

  -webkit-box-shadow: 0px 2px 14px 1px var(--black);
  box-shadow: 0px 2px 14px 1px var(--black);
}
.sidenav .call-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 35px;
  height: 31px;
  right: 20px;
  bottom: 20px;
  z-index: 100;
  cursor: pointer;
  background-color: var(--white);
  border-radius: 50%;

  -webkit-box-shadow: 0px 2px 14px 1px var(--black);
  box-shadow: 0px 2px 14px 1px var(--black);
}
.dim-background {
  display: none;
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 450;
  width: 100%;
  height: 100%;
  background: rgb(0, 0, 0, 0.5);
}
.dim-background.hide {
  display: none;
}
.dim-background.active {
  display: block;
}
.sidenav .sidenav-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  position: fixed;
  top: 0;
  width: 270px;
  box-sizing: border-box;
  z-index: 999;
  background-color: var(--white);
  padding: 20px 45px 20px 20px;
  height: calc(var(--vh, 1vh) * 100);
  left: -300px;
  transition: left 200ms linear;
  overflow-y: scroll;
  color: var(--black);
  border-top-right-radius: 20px;
}
.sidenav .sidenav-div.active {
  left: 0px;
}

.sidenav .titles {
  display: flex;
  flex-direction: column;

  gap: 20px;
}
.sidenav .titles .logo {
  cursor: pointer;
  width: 161px;
  height: 68px;
}
.sidenav .titles .title {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.sidenav .titles .title > span {
  padding-right: 5px;
}
.sidenav .auth-button {
  width: 130px;
  height: 40px;
  color: var(--white);
  border-radius: 40px;
  padding: 10px 25px;
  background: transparent;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, 0.5),
    7px 7px 20px 0px rgba(0, 0, 0, 0.1), 4px 4px 5px 0px rgba(0, 0, 0, 0.1);
  outline: none;

  background: var(--black);
  border: none;
  z-index: 1;
}
.sidenav .auth-button:after {
  position: absolute;
  content: "";
  width: 0;
  height: 100%;
  top: 0;
  right: 0;
  z-index: -1;
  background-color: var(--yellow);
  border-radius: 40px;
  box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, 0.5),
    7px 7px 20px 0px rgba(0, 0, 0, 0.1), 4px 4px 5px 0px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}
.sidenav .auth-button:hover {
  color: var(--black);
}
.sidenav .auth-button:hover:after {
  left: 0;
  width: 100%;
}
.sidenav .auth-button:active {
  top: 2px;
}
.sidenav .auth-button.logout:hover span {
  display: none;
}
.sidenav .auth-button.logout:hover:before {
  content: "Logout";
}
