.contact-page .form-container {
  max-width: 400px;
  margin: 100px;
  padding: 50px;
  border: 1px solid #e5ca50;
  border-radius: 5px;
  background-color: #f5cb5c;
}
.contact-page {
  height: calc(100vh - 122px);
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  background-image: url("../../assets/con.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center right; /* Adjusted property */
  padding: 10px;
  box-sizing: border-box;
}

.contact-page .title {
  color: var(--black);
}
.contact-page .form-group {
  margin-bottom: 20px;
}

.contact-page .error {
  color: red;
}

.contact-page input,
.contact-page textarea {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
  margin-top: 5px;
}

.contact-page button {
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.contact-page .contact-us-footer {
  display: flex;
  justify-content: space-around;

  background-color: #0000004f;
  padding: 10px;
  border-radius: 10px;
}
.contact-page .contact-us-footer a {
  text-decoration: none;
  color: var(--black);
}
@media screen and (max-width: 800px) {
  .contact-page {
    height: 100vh;
  }
  .contact-page .form-container {
    margin: 10px;
    /* height: 80vh; */
  }
}
@media screen and (max-width: 550px) {
  .contact-page .contact-us-footer {
    text-align: center;
    flex-direction: column;
    gap: 10px;
  }
}
