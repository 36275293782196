.location .add {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-end;
  background: var(--green);
  border-radius: 6px;
  padding: 20px;
  width: 75px;
  margin-bottom: 20px;
}
.location .list-content .list-details {
  display: flex;
  width: 100%;
  justify-content: space-around;
}
.location .list-content .list-details > div {
  display: flex;
  width: 100%;
  justify-content: space-around;
}
.location .list-content .list-details .ride-name {
  font-weight: 600;
}
.location .list-content .list-details .travel-time,
.location .list-content .list-details .price {
  font-weight: 600;
  color: var(--blue);
}
@media screen and (max-width: 800px) {
  .location .list-content .list-details > div {
    flex-direction: column;
  }
}

@media screen and (max-width: 450px) {
  .location .list-content .action-buttons {
    align-items: flex-start;
  }
  .location .list-content .list-details {
    flex-direction: column;
  }
}
