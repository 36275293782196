.admin-booking {
  display: flex;
  flex-direction: column;
}
.admin-booking .booking-card {
  display: flex;
  flex-direction: column;
  margin: 20px 20px;
  padding: 20px;
  border-radius: 6px;
  font-size: 20px;
  background: linear-gradient(89.1deg, var(--lightYellow), var(--lightYellow));
  box-shadow: 5px 5px 10px -2px var(--black);
  transition: transform 0.2s;
}
/* .admin-booking .booking-card:hover {
  transform: scale(1.01);
  background: linear-gradient(89.1deg, var(--lightYellow), var(--yellow));
} */
.admin-booking .booking-card > div {
  padding: 5px 0;
}
.admin-booking .booking-card .booking-header {
  display: flex;
  justify-content: space-between;
  font-weight: 700;
}
.admin-booking .booking-card .booking-header .scheduled-for {
  color: var(--boldGreen);
}
.admin-booking .booking-card a {
  font-weight: 500;
  text-decoration: none;
  color: var(--gray);
}
.admin-booking .booking-card .booked-on {
  font-weight: 500;
  color: var(--gray);
}
.admin-booking .booking-card .content {
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  color: var(--gray);
}
.admin-booking .booking-card .content .booking-location {
  display: flex;
  flex-direction: column;
}
.admin-booking .booking-card .content .booking-location .city-name {
  color: var(--blue);
}
.admin-booking .booking-card .content .price {
  color: var(--red);
}
.admin-booking .booking-card .allocate-driver {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
  color: var(--blue);
}

.admin-booking .booking-card .allocate-driver .title {
  font-weight: 700;
}

.admin-booking .booking-card .allocate-driver button {
  outline: none;
  border: none;
  cursor: pointer;
  height: 30px;
  padding: 0 20px;
  max-width: 190px;

  background: linear-gradient(89.1deg, var(--yellow), var(--yellow));
  box-shadow: 5px 5px 10px -2px var(--black);
  border-radius: 4px;
  transition: all 0.2s ease-in-out;
}

.admin-booking .booking-card .allocate-driver .cancel {
  background: var(--red);
}

.admin-booking .booking-card .allocate-driver button:hover {
  background: var(--white);
}
@media screen and (max-width: 720px) {
  .admin-booking .booking-card .content,
  .admin-booking .booking-card .booking-header {
    flex-direction: column;
  }
  .admin-booking .booking-card .content > div,
  .admin-booking .booking-card .booking-header > div {
    padding: 5px 0;
  }
}
