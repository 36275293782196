:root {
  --black: #000000;
  --gray: #5d5d5d;
  --grayTr: #ffffff4d;
  --white: #ffffff;
  --whiteTr: #ffffffc7;
  --yellow: #fab722;
  --yellowTr: #fab622de;
  --lightYellow: #f4f0c7;
  --red: #d91616;
  --blue: #4242e9;
  --green: #25e35e;
  --boldGreen: #08541f;
}

* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
/* ::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  border-radius: 5px;
  background: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  background: #c2c2c2;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #c2c2c2;
} */
