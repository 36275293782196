.spinner {
  z-index: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  height: 100vh;
  width: 100vw;
  background: linear-gradient(72.44deg, var(--yellowTr), var(--whiteTr));
}
