.my-bookings {
  display: flex;
  flex-direction: column;
}
.my-bookings .booking-card {
  display: flex;
  flex-direction: column;
  margin: 20px 20px;
  padding: 20px;
  border-radius: 6px;
  font-size: 20px;
  background: linear-gradient(89.1deg, var(--lightYellow), var(--lightYellow));
  box-shadow: 5px 5px 10px -2px var(--black);
  transition: transform 0.2s;
}
.my-bookings .booking-card:hover {
  transform: scale(1.01);
  background: linear-gradient(89.1deg, var(--lightYellow), var(--yellow));
}
.my-bookings .booking-card > div {
  padding: 5px 0;
}
.my-bookings .booking-card .booking-header {
  display: flex;
  justify-content: space-between;
  font-weight: 700;
}
.my-bookings .booking-card .booking-header .scheduled-for {
  color: var(--boldGreen);
}
.my-bookings .booking-card .booked-on {
  font-weight: 500;
  color: var(--gray);
}
.my-bookings .booking-card .content {
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  color: var(--gray);
}
.my-bookings .booking-card .content .booking-location {
  display: flex;
  flex-direction: column;
}
.my-bookings .booking-card .content .price {
  color: var(--red);
}
.my-bookings .booking-card .driver-info {
  display: flex;
  justify-content: space-between;
  font-weight: 700;
  color: var(--blue);
}
.my-bookings .booking-card .driver-info > a {
  text-decoration: none;
}

/* status */
.my-bookings .status .Pending {
  color: var(--black);
}
.my-bookings .status .Confirmed {
  color: var(--green);
}
.my-bookings .status .Cancelled {
  color: var(--red);
}

.my-bookings .booking-card button {
  outline: none;
  border: none;
  cursor: pointer;
  height: 30px;
  padding: 0 20px;
  max-width: 190px;
  margin-top: 20px;

  background: linear-gradient(89.1deg, var(--yellow), var(--yellow));
  box-shadow: 5px 5px 10px -2px var(--black);
  border-radius: 4px;
  transition: all 0.2s ease-in-out;
}

.my-bookings .booking-card .cancel {
  background: var(--red);
}
