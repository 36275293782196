.signup-page {
  /* background-image: url("https://images.unsplash.com/photo-1532440662719-69cb56b3fe3d?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"); */
  padding-top: 20px;
  height: calc(100vh - 143px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: radial-gradient(circle at 20% 50%, #d4d4d4, transparent),
    radial-gradient(circle at 80% 50%, #fab722, transparent),
    radial-gradient(circle at 50% 50%, #d4d4d4, #d4d4d4),
    radial-gradient(circle at 50% 50%, #fab722, #fab722);
  background-size: 300% 300%;
  animation: gradient 12s ease infinite;
}
@keyframes gradient {
  0% {
    background-position: 0% 50%, 0% 50%, 50% 50%, 50% 50%;
  }
  50% {
    background-position: 100% 50%, 100% 50%, 50% 50%, 50% 50%;
  }
  100% {
    background-position: 0% 50%, 0% 50%, 50% 50%, 50% 50%;
  }
}
.signup-page .title {
  font-size: 24px;
  font-weight: 700;
}
.signup-page .login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.signup-page .login-container .input-container {
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 10px 0;
}
.signup-page .login-container > button {
  margin: 10px 0;
}
.signup-page .login-container .input {
  outline: none;
  border: none;
  padding: 0 10px;
  width: 400px;
  height: 50px;

  background: linear-gradient(89.1deg, var(--yellow), var(--yellow));
  box-shadow: 5px 5px 10px -2px var(--black);
  border-radius: 6px;
  transition: all 0.2s ease-in-out;
}
.signup-page .login-container .input.input-error {
  border: 1px solid var(--red);
}
.signup-page .login-container .error {
  position: absolute;
  top: 57px;
  color: var(--red);
  font-size: 9px;
  margin-left: 3px;
  align-self: flex-start;
}
.signup-page .login-container .input-container .password-icon {
  cursor: pointer;
  position: absolute;
  top: 18px;
  right: 10px;
}
.signup-page .login-container button {
  outline: none;
  border: none;
  cursor: pointer;
  height: 30px;
  padding: 0 20px;

  background: linear-gradient(89.1deg, var(--yellow), var(--yellow));
  box-shadow: 5px 5px 10px -2px var(--black);
  border-radius: 4px;
  transition: all 0.2s ease-in-out;
}
.signup-page .login-container input:focus,
.signup-page .login-container button:hover {
  background: var(--white);
}
.signup-page .login-container input::placeholder,
.signup-page .login-container input,
.signup-page .login-container button {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: var(--black);
}

.signup-page .login-container input::placeholder {
  color: var(--white);
}
.signup-page .login-container input:focus::placeholder {
  color: var(--black);
}

.signup-page .signup-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;

  width: 300px;
  padding: 10px 3%;
  margin-top: 30px;

  color: var(--lightBlack);

  background: linear-gradient(89.1deg, var(--blue_01), var(--red_01));
  box-shadow: 5px 5px 10px -2px var(--black_006);
  border-left: 1px solid var(--white_03);
  border-top: 1px solid var(--white_03);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  border-radius: 22px;

  transition: all 0.2s ease-in-out;
}
.signup-page .signup-section:hover {
  background: var(--white_03);
}
@media screen and (max-width: 800px) {
  .signup-page {
    height: calc(100vh - 20px);
  }
}
@media screen and (max-width: 450px) {
  .signup-page .login-container .input {
    width: 300px;
  }
}
