.car-section {
  display: flex;
  /* height: 600px; */
  background-color: var(--white);
  align-items: center;
  padding: 20px 80px;
}
/* section 1 */
.car-section .section-1 {
  display: flex;
  flex: 1;
  flex-direction: column;
}
.car-section .section-1 > * {
  margin: 20px 0;
}
.car-section .section-1 .title {
  font-size: 70px;
  font-family: "monaco";
  color: #000;
  animation: fadeIn 3s forwards;
}

.car-section .section-1 .content {
  font-size: 20px;
  font-family: "monaco";
  color: #3b3b3b;
  animation: fadeIn 4s forwards;
}

.car-section .section-1 .signup-button {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  width: 90px;
  height: 37px;
  border-radius: 13px;
  background: var(--yellow);
  color: var(--white);
  transition: all 0.3s;
  z-index: 1;
  box-sizing: border-box;
  font-weight: 700;
}

.car-section .section-1 .signup-button:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--gray);
  border-radius: 10rem;
  z-index: -2;
}

.car-section .section-1 .signup-button:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 100%;
  background-color: var(--black);
  transition: all 0.3s;
  border-radius: 10rem;
  z-index: -1;
}
.car-section .section-1 .signup-button:hover {
  color: var(--white);
}
.car-section .section-1 .signup-button:hover:before {
  width: 100%;
}

/* .car-section .section-1 .signup-button:hover {
  background: var(--yellow);
  color: var(--white);
} */

/* section 2 */
.car-section .image-section {
  flex: 2;
}
.car-section .image-section img {
  width: 100%;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media screen and (max-width: 1000px) {
  .car-section .section-1 .title {
    font-size: 40px;
  }
}
@media screen and (max-width: 720px) {
  .car-section {
    padding: 20px 40px;
  }
}
@media screen and (max-width: 550px) {
  .car-section {
    flex-direction: column;
  }
  .car-section .image-section {
    flex: 1;
  }
}
@media screen and (max-width: 450px) {
  .car-section .section-1 .title {
    font-size: 30px;
  }
}
