.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: var(--lightYellow);
  padding: 20px;
  bottom: 0;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.footer .footer-links {
  text-align: center;
}
.footer a {
  color: var(--gray);
  text-decoration: none;
  padding: 0 10px;
}

.footer a:hover {
  color: var(--black);
}

.social-icons {
  margin-top: 10px;
}

.social-icons a {
  padding: 0 10px;
}

.social-icons a:hover {
  color: var(--black);
}
