.map-section {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 30px;
}
.map-section .trips {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: space-around;
  align-items: center;
}
.map-section .map-area {
  height: 400px;
  width: 640px;
  border: 2px solid #eee;
  display: inline-block;
  overflow: hidden;
  border-radius: 20px;
}
.map-section .map-design {
  flex: 2;
}

.map-section .map-design img {
  width: 100%;
  object-fit: cover;
}
@media screen and (max-width: 1100px) {
  .map-section .map-area {
    height: 300px;
    width: 540px;
  }
}
@media screen and (max-width: 900px) {
  .map-section {
    flex-direction: column;
  }
  .map-section .map-design {
    flex-direction: row;
  }
  .map-section .map-area {
    width: 90%;
    margin-top: 20px;
  }
}
@media screen and (max-width: 500px) {
  .map-section .map-area {
    height: 200px;
  }
}
