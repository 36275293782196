.search-area {
  display: flex;
  border-radius: 10px;
  padding: 10px;
  background: linear-gradient(89.1deg, var(--white), var(--white));
  box-shadow: 5px 5px 10px -2px var(--black);
  gap: 10px;
}
.search-area .location {
  display: flex;
  gap: 10px;
}
.search-area .parameters {
  display: flex;
  gap: 10px;
}
/* date */
.search-area .date {
  position: relative;
}
.search-area .date .title {
  position: absolute;
  font-size: 12px;
  color: var(--yellow);
  z-index: 1;
  left: 11px;
  top: 10px;
}
.search-area .date input {
  outline: none;
  border: none;
  box-sizing: border-box;
  padding: 10px 10px 0px 10px;
  width: 190px;
  height: 57px;
  font-size: 18px;
  border: 1px solid var(--yellow);
  border-radius: 7px;
  /* background: linear-gradient(89.1deg, var(--lightYellow), var(--yellow));
  box-shadow: 5px 5px 10px -2px var(--black); */
}

/* passengers */
.search-area .passengers {
  position: relative;
  border: 1px solid var(--yellow);
  border-radius: 7px;
  box-sizing: border-box;
  padding: 10px;
  font-size: 18px;
  width: 190px;
}
.search-area .passengers.error {
  border: 1px solid var(--red);
}
.search-area .passengers .passengers-header {
  cursor: pointer;
}
.search-area .passengers .title {
  font-size: 12px;
  color: var(--yellow);
}

.search-area .passengers .popup-contents {
  z-index: 1;
  position: absolute;
  display: flex;
  top: 64px;
  left: 0;
  font-weight: 500;
  gap: 10px;
  width: 190px;
  flex-direction: column;
  font-size: 14px;
  border: 1px solid var(--yellow);
  border-radius: 4px;
  padding: 10px;
  background: linear-gradient(89.1deg, var(--white), var(--white));
  box-shadow: 5px 5px 10px -2px var(--black);
}

.search-area .passengers .popup-contents .popup-content {
  display: flex;
  justify-content: space-between;
}

.search-area .passengers .popup-contents .popup-content .controller {
  display: flex;
  gap: 10px;
}

.search-area .passengers .popup-contents .popup-content .controller span {
  width: 10px;
}
.search-area .passengers .popup-contents .popup-content .controller svg {
  cursor: pointer;
}

@media screen and (max-width: 1000px) {
  .search-area {
    flex-direction: column;
  }
}
@media screen and (max-width: 550px) {
  .search-area .location,
  .search-area .parameters {
    flex-direction: column;
  }
}
