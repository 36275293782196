.background {
  background-image: url("../../assets/hoi.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center ; /* Adjusted property */
  height: 100vh;
  display: flex;
  justify-content: center;
  padding-top: 20px;
}
.background .title {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: rgb(52, 50, 50);
  font-size: 60px;
}
.service {
  color: var(--white);
}
.background .content {
  background-color: rgba(255, 255, 255, 0.5); /* Transparent white background */
  padding: 20px;
  text-align: center;
  border-radius: 10px;
}

.background .content h2 {
  font-size: 36px;
  margin: 0;
  color: rgb(52, 45, 45);
}

.background .services-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
}

.background .service-box {
  background-color: rgba(0, 0, 0, 0.7); /* Transparent black background */
  color: #fff;
  padding: 10px 20px;
  margin: 0 10px;
  border-radius: 5px;
  text-align: center;
  font-size: 18px;
}
@media screen and (max-width: 720px) {
  .background .title {
    font-size: 40px;
  }
}

@media screen and (max-width: 500px) {
  .background .title {
    font-size: 30px;
  }
  .background .content h2 {
    font-size: 20px;
  }
  .background .services-container {
    font-size: 15px;
  }
}

@media screen and (max-width: 380px) {
  .background .title {
    font-size: 20px;
  }
  .background .content h2 {
    font-size: 15px;
  }
  .background .services-container {
    font-size: 10px;
  }
}
